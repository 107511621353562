import revive_payload_client_4sVQNw7RlN from "/opt/render/project/src/webapp/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/render/project/src/webapp/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/render/project/src/webapp/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/render/project/src/webapp/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/render/project/src/webapp/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/render/project/src/webapp/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/render/project/src/webapp/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/render/project/src/webapp/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/webapp/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/render/project/src/webapp/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/opt/render/project/src/webapp/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/render/project/src/webapp/.nuxt/sentry-client-config.mjs";
import primevue_plugin_egKpok8Auk from "/opt/render/project/src/webapp/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "/opt/render/project/src/webapp/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import plugin_H2YfXjPOAw from "/opt/render/project/src/webapp/node_modules/mixpanel-nuxt/dist/runtime/plugin.js";
import plugin_I4dbrL2rYz from "/opt/render/project/src/webapp/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import mixpanel_pageview_tracker_X8fxO9PbiN from "/opt/render/project/src/webapp/plugins/mixpanel-pageview-tracker.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  plugin_H2YfXjPOAw,
  plugin_I4dbrL2rYz,
  mixpanel_pageview_tracker_X8fxO9PbiN
]